import { z } from 'zod';
import { ContractSuretyType, SuretyQuoteStatus } from '../../../../../enums.mjs';
import '../../../../../types/opaque.mjs';
import { zswitch } from '../../../../../types/utils.mjs';
import '../../../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../../../types/credit-report/company-credit-report.mjs';
import '../../../../../types/credit-report/individual-credit-report.mjs';
import '../../../../../types/payment/payment-template.mjs';
import '../../../../../types/surety/account/surety-account.types.mjs';
import '../../../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../../../types/surety/bond/surety-bond.types.mjs';
import '../../../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../../../../types/user/user.types.mjs';
import '../../../../../types/entityRelation/entityRelation.mjs';
import { Bid } from './bid-contract-surety-quote.dto.mjs';
import { UpdateStatus } from './contract-surety-quote-mixins.dto.mjs';
import { Final } from './final-contract-surety-quote.dto.mjs';

var Contract;
(function (Contract) {
    (function (UpdateStatus$1) {
        (function (Request) {
            const approveSchema = zswitch((input) => {
                switch (input.contractSuretyType) {
                    case ContractSuretyType.bid:
                        return Bid.UpdateStatus.Request.approveSchema;
                    case ContractSuretyType.bid_to_final:
                        return Final.UpdateStatus.Request.approveSchema;
                    case ContractSuretyType.final:
                        return Final.UpdateStatus.Request.approveSchema;
                    default:
                        return z.never();
                }
            });
            const declineSchema = UpdateStatus.Request.baseSchema.extend({
                status: z.literal(SuretyQuoteStatus.declined),
            });
            Request.schema = zswitch((input) => {
                switch (input.status) {
                    case SuretyQuoteStatus.approved:
                        return approveSchema;
                    case SuretyQuoteStatus.declined:
                        return declineSchema;
                    default:
                        return z.never();
                }
            });
        })(UpdateStatus$1.Request || (UpdateStatus$1.Request = {}));
    })(Contract.UpdateStatus || (Contract.UpdateStatus = {}));
})(Contract || (Contract = {}));

export { Contract };
