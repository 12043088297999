import { z } from 'zod';
import { SuretyType, ContractSuretyType, contractSuretyBondTypeSchema, suretyBondRenewalMethodSchema, dateTimeSchema } from '../../../../../enums.mjs';
import { timePeriodSchema } from '../../../../../types/opaque.mjs';
import '../../../../../types/utils.mjs';
import '../../../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../../../types/credit-report/company-credit-report.mjs';
import '../../../../../types/credit-report/individual-credit-report.mjs';
import '../../../../../types/payment/payment-template.mjs';
import '../../../../../types/surety/account/surety-account.types.mjs';
import '../../../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import { Obligee } from '../../../../../types/surety/bond/surety-bond.types.mjs';
import '../../../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../../../../types/user/user.types.mjs';
import '../../../../../types/entityRelation/entityRelation.mjs';
import { principalSignerSchema } from '../../../../../types/surety/bond-form/issued/contract/issued-contract-surety-bond-form.types.mixins.mjs';

var Approve;
(function (Approve) {
    (function (Request) {
        Request.schema = z.object({
            suretyType: z.literal(SuretyType.contract),
            contractSuretyType: z.literal(ContractSuretyType.final),
            bondFormType: contractSuretyBondTypeSchema,
            cancellable: z.boolean(),
            continuous: z.boolean(),
            renewable: z.boolean(),
            renewalMethod: suretyBondRenewalMethodSchema.nullish(),
            numberOfPeriods: z.number().nullish(),
            renewOnDay: z
                .object({
                month: z.number().min(1).max(12),
                day: z.number(),
            })
                .nullish(),
            renewThroughYear: z.number().nullish(),
            periodLength: timePeriodSchema.nullish(),
        });
    })(Approve.Request || (Approve.Request = {}));
})(Approve || (Approve = {}));
var Upload;
(function (Upload) {
    (function (AgentIssue) {
        (function (Request) {
            Request.schema = z.object({
                suretyType: z.literal(SuretyType.contract),
                contractSuretyType: z.literal(ContractSuretyType.final),
                bondForm: z.object({
                    file: z.object({
                        name: z.string(),
                        base64: z.string(),
                    }),
                    data: z.object({
                        bondNumber: z.string(),
                        effectiveDate: dateTimeSchema,
                        contractDate: dateTimeSchema,
                        contractDescription: z.string(),
                        obligees: Obligee.schema.array(),
                        principalSigner: principalSignerSchema.nullish(),
                    }),
                }),
            });
        })(AgentIssue.Request || (AgentIssue.Request = {}));
    })(Upload.AgentIssue || (Upload.AgentIssue = {}));
    (function (PhysicalIssue) {
        (function (Request) {
            Request.schema = z.object({
                suretyType: z.literal(SuretyType.contract),
                contractSuretyType: z.union([
                    z.literal(ContractSuretyType.final),
                    z.literal(ContractSuretyType.bid_to_final),
                ]),
                bondFormType: contractSuretyBondTypeSchema,
                attributes: z.object({
                    cancellable: z.boolean(),
                    continuous: z.boolean(),
                    renewable: z.boolean(),
                    renewalMethod: suretyBondRenewalMethodSchema.nullish(),
                    numberOfPeriods: z.number().nullish(),
                    renewOnDay: z
                        .object({
                        month: z.number().min(1).max(12),
                        day: z.number(),
                    })
                        .nullish(),
                    renewThroughYear: z.number().nullish(),
                    periodLength: timePeriodSchema.nullish(),
                }),
                properties: z.object({
                    effectiveDate: dateTimeSchema,
                }),
                file: z.object({
                    name: z.string(),
                    base64: z.string(),
                }),
            });
        })(PhysicalIssue.Request || (PhysicalIssue.Request = {}));
    })(Upload.PhysicalIssue || (Upload.PhysicalIssue = {}));
})(Upload || (Upload = {}));

export { Approve, Upload };
