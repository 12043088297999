import { z } from 'zod';
import '../../../../types/opaque.mjs';
import { zswitch } from '../../../../types/utils.mjs';
import '../../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../../types/credit-report/company-credit-report.mjs';
import '../../../../types/credit-report/individual-credit-report.mjs';
import '../../../../types/payment/payment-template.mjs';
import '../../../../types/surety/account/surety-account.types.mjs';
import '../../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../../types/surety/bond/surety-bond.types.mjs';
import '../../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../../../types/user/user.types.mjs';
import '../../../../types/entityRelation/entityRelation.mjs';
import { Create as Create$3 } from './bid-contract-surety-bond-issuance.dto.mjs';
import * as bidContractSuretyBondIssuance_dto from './bid-contract-surety-bond-issuance.dto.mjs';
export { bidContractSuretyBondIssuance_dto as Bid };
import { Create as Create$2 } from './bid-to-final-contract-surety-bond-issuance.dto.mjs';
import * as bidToFinalContractSuretyBondIssuance_dto from './bid-to-final-contract-surety-bond-issuance.dto.mjs';
export { bidToFinalContractSuretyBondIssuance_dto as BidToFinal };
import { Create as Create$1 } from './final-contract-surety-bond-issuance.dto.mjs';
import * as finalContractSuretyBondIssuance_dto from './final-contract-surety-bond-issuance.dto.mjs';
export { finalContractSuretyBondIssuance_dto as Final };

var Create;
(function (Create) {
    (function (Request) {
        Request.schema = zswitch((input) => {
            switch (input.contractSuretyType) {
                case "bid":
                    return Create$3.Request.schema;
                case "bid_to_final":
                    return Create$2.Request.schema;
                case "final":
                    return Create$1.Request.schema;
                default:
                    return z.never();
            }
        });
    })(Create.Request || (Create.Request = {}));
})(Create || (Create = {}));

export { Create };
