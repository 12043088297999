import { z } from 'zod';
import { suretyAccountUnderwritingStatusSchema, contractSuretyAccountUnderwritingModelSizeSchema, indemnityAgreementSignatureMethodSchema } from '../../../../enums.mjs';
import { suretyAccountUnderwritingId, isoDateTimeSchema, companyContactId, individualContactId, email } from '../../../../types/opaque.mjs';
import '../../../../types/utils.mjs';
import '../../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../../types/credit-report/company-credit-report.mjs';
import '../../../../types/credit-report/individual-credit-report.mjs';
import '../../../../types/payment/payment-template.mjs';
import '../../../../types/surety/account/surety-account.types.mjs';
import '../../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../../types/surety/bond/surety-bond.types.mjs';
import '../../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../../../types/user/user.types.mjs';
import '../../../../types/entityRelation/entityRelation.mjs';
import { Get as Get$1 } from '../indemnity-agreement/surety-indemnity-agreement.dto.mjs';

var Get;
(function (Get) {
    (function (Response) {
        (function (Underwriting) {
            Underwriting.schema = z.object({
                id: suretyAccountUnderwritingId,
                status: suretyAccountUnderwritingStatusSchema,
                reviewReasons: z.array(z.object({ description: z.string() })),
                reviewNote: z.string().optional(),
                score: z.number().optional(),
                singleLimit: z.number().optional(),
                aggregateLimit: z.number().optional(),
                expired: z.boolean(),
                createdAt: isoDateTimeSchema,
                availableModelSizes: z.array(contractSuretyAccountUnderwritingModelSizeSchema),
                stale: z.boolean(),
            });
        })(Response.Underwriting || (Response.Underwriting = {}));
        (function (Indemnity) {
            Indemnity.schema = z.object({
                upToDate: z.boolean(),
                allowedSignatureMethods: z.array(indemnityAgreementSignatureMethodSchema).min(1),
                requiredIndemnitors: z.object({
                    companies: z.array(z.object({
                        id: companyContactId,
                        name: z.string(),
                    })),
                    individuals: z
                        .array(z.object({
                        id: individualContactId,
                        name: z.string(),
                        email: email.optional(),
                    }))
                        .default([]),
                }),
                indemnityWaivers: z.object({
                    companies: z
                        .array(z.object({
                        id: companyContactId,
                        name: z.string(),
                    }))
                        .default([]),
                    individuals: z
                        .array(z.object({
                        id: individualContactId,
                        name: z.string(),
                        email: email.optional(),
                    }))
                        .default([]),
                }),
                indemnityAgreements: z.array(Get$1.Response.schema),
            });
        })(Response.Indemnity || (Response.Indemnity = {}));
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));

export { Get };
