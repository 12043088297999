import { ReactNode } from "react";
import { cn } from "../../../../../../components/lib/utils";

export const AdminActionItemLayout = ({
	containerClassName,
	statusIcon,
	content,
}: { statusIcon: ReactNode; content: ReactNode; containerClassName?: string }) => {
	return (
		<div
			className={cn(
				"p-[24px] flex justify-between items-center border-b border-gray-100",
				containerClassName,
			)}
		>
			<div className="flex gap-5 items-start justify-start w-full">
				<div className="self-start min-w-fit">{statusIcon}</div>
				<>{content}</>
			</div>
		</div>
	);
};
