import { z } from 'zod';
import { nameSuffixSchema } from '../../enums.mjs';
import { individualOFACCheckId, individualContactId, firstName, lastName, addressSchema, isoDateTimeSchema } from '../../types/opaque.mjs';
import '../../types/utils.mjs';
import '../../types/agency/carrier-agency-relationship.types.mjs';
import '../../types/credit-report/company-credit-report.mjs';
import '../../types/credit-report/individual-credit-report.mjs';
import '../../types/payment/payment-template.mjs';
import '../../types/surety/account/surety-account.types.mjs';
import '../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../types/surety/bond/surety-bond.types.mjs';
import '../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../types/user/user.types.mjs';
import '../../types/entityRelation/entityRelation.mjs';

var Get;
(function (Get) {
    Get.responseSchema = z.object({
        id: individualOFACCheckId,
        individualContactId: individualContactId,
        success: z.boolean(),
        firstName: firstName,
        middleInitial: z.string().length(1).optional(),
        lastName: lastName,
        address: addressSchema,
        hit: z.boolean().optional(),
        createdAt: isoDateTimeSchema,
        updatedAt: isoDateTimeSchema,
    });
})(Get || (Get = {}));
var Create;
(function (Create) {
    Create.requestSchema = z.object({
        individualContactId: individualContactId.optional(),
        firstName: firstName,
        middleInitial: z.string().length(1).optional(),
        lastName: lastName,
        suffix: nameSuffixSchema.optional(),
        address: addressSchema,
    });
    Create.responseSchema = Get.responseSchema;
})(Create || (Create = {}));

export { Create, Get };
