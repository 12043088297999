import { z } from 'zod';
import { finalContractSuretyBondClosedReasonSchema } from '../../../../enums.mjs';
import '../../../../types/opaque.mjs';
import { currencySchema, zswitch } from '../../../../types/utils.mjs';
import '../../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../../types/credit-report/company-credit-report.mjs';
import '../../../../types/credit-report/individual-credit-report.mjs';
import '../../../../types/payment/payment-template.mjs';
import '../../../../types/surety/account/surety-account.types.mjs';
import '../../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../../types/surety/bond/surety-bond.types.mjs';
import '../../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../../../types/user/user.types.mjs';
import '../../../../types/entityRelation/entityRelation.mjs';
import { Close as Close$1 } from '../../../../types/surety/bond/contract/final-contract-surety-bond.types.mjs';
import { Get as Get$1 } from './contract-surety-bond-mixins.dto.mjs';

var Get;
(function (Get) {
    (function (Response) {
        Response.rawSchema = z.object({
            contractSuretyType: z.literal("final"),
            contractAmount: currencySchema,
            closedReason: finalContractSuretyBondClosedReasonSchema.optional(),
        });
        Response.schema = Get$1.Response.baseSchema.extend(Response.rawSchema.shape);
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));
var Close;
(function (Close) {
    (function (Request) {
        Request.baseSchema = Close$1.Payload.baseSchema
            .omit({ closedNote: true })
            .extend({
            closedReason: finalContractSuretyBondClosedReasonSchema.extract([
                "project_completed",
                "returned_by_obligee",
                "other",
            ]),
        });
        Request.allOtherReasonsSchema = Close$1.Payload.allOtherReasonsSchema.extend({
            closedReason: finalContractSuretyBondClosedReasonSchema.extract([
                "returned_by_obligee",
                "other",
            ]),
            closedNote: z.string(),
        });
        Request.schema = Request.baseSchema.and(zswitch((input) => {
            switch (input.closedReason) {
                case "project_completed":
                    return Close$1.Payload.projectCompletedReasonsSchema;
                default:
                    return Request.allOtherReasonsSchema;
            }
        }));
    })(Close.Request || (Close.Request = {}));
})(Close || (Close = {}));

export { Close, Get };
