import { z } from 'zod';
import { companyCreditReportFailureReasonSchema } from '../../enums.mjs';
import { companyCreditReportId, partialAddressSchema, phoneNumber, fein, fsr, companyContactId, isoDateTimeSchema } from '../../types/opaque.mjs';
import { buildCreditReportVendorResponseDtoSchema } from '../../types/utils.mjs';
import '../../types/agency/carrier-agency-relationship.types.mjs';
import { Vendor as Vendor$1 } from '../../types/credit-report/company-credit-report.mjs';
import '../../types/credit-report/individual-credit-report.mjs';
import '../../types/payment/payment-template.mjs';
import '../../types/surety/account/surety-account.types.mjs';
import '../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../types/surety/bond/surety-bond.types.mjs';
import '../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../types/user/user.types.mjs';
import '../../types/entityRelation/entityRelation.mjs';

var Vendor;
(function (Vendor) {
    Vendor.responseSchema = buildCreditReportVendorResponseDtoSchema(Vendor$1.reportSchema);
})(Vendor || (Vendor = {}));
var Admin;
(function (Admin) {
    (function (Response) {
        Response.schema = z.object({
            id: companyCreditReportId,
            success: z.boolean(),
            failureReason: companyCreditReportFailureReasonSchema.optional(),
            name: z.string().optional(),
            address: partialAddressSchema.optional(),
            phone: phoneNumber.optional(),
            fein: fein.optional(),
            yearsInBusinessOrCreditAge: z.number().positive().optional(),
            bankruptcy: z.boolean().optional(),
            judgmentBalance: z.number().optional(),
            lienBalance: z.number().optional(),
            experianBin: z.string().optional(),
            experianFsr: fsr.optional(),
            experianFsrFactors: z.array(Vendor$1.experianFSRFactorSchema).optional(),
            experianFsrTrends: z.array(Vendor$1.experianFSRTrendSchema).optional(),
            experianExecutiveElements: Vendor$1.experianExecutiveElementsSchema.optional(),
            companyContactId: companyContactId,
            createdAt: isoDateTimeSchema,
            updatedAt: isoDateTimeSchema,
        });
    })(Admin.Response || (Admin.Response = {}));
})(Admin || (Admin = {}));

export { Admin, Vendor };
