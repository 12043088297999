import { z } from 'zod';
import { actionItemStatusSchema, dateTimeSchema } from '../../enums.mjs';
import { actionItemId, userId } from '../../types/opaque.mjs';
import '../../types/utils.mjs';
import '../../types/agency/carrier-agency-relationship.types.mjs';
import '../../types/credit-report/company-credit-report.mjs';
import '../../types/credit-report/individual-credit-report.mjs';
import '../../types/payment/payment-template.mjs';
import '../../types/surety/account/surety-account.types.mjs';
import '../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../types/surety/bond/surety-bond.types.mjs';
import '../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../types/user/user.types.mjs';
import { Relations } from '../../types/entityRelation/entityRelation.mjs';

var Get;
(function (Get) {
    (function (Response) {
        Response.schema = Relations.ActionItems.schema.and(z.object({
            id: actionItemId,
            ownerId: userId,
            assigneeId: userId,
            title: z.string(),
            content: z.string(),
            status: actionItemStatusSchema,
            dueAt: dateTimeSchema.nullish(),
            completedByUserId: userId.nullish(),
            completedAt: dateTimeSchema.nullish(),
            createdAt: dateTimeSchema,
            updatedAt: dateTimeSchema,
        }));
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));
var Create;
(function (Create) {
    (function (Request) {
        Request.schema = Relations.ActionItems.schema.and(z.object({
            title: z.string(),
            content: z.string().nullish(),
            dueAt: dateTimeSchema.nullish(),
            status: actionItemStatusSchema,
            completedByUserId: userId.nullish(),
            completedAt: dateTimeSchema.nullish(),
            assigneeId: userId,
        }));
    })(Create.Request || (Create.Request = {}));
})(Create || (Create = {}));

export { Create, Get };
