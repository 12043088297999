import { NoteTopicId, Relations } from "@inrev/common";
import { useState } from "react";
import { HiPlus } from "react-icons/hi";
import { AdminNoteEditor } from "../components/AdminNoteEditor";

export const AdminNotesNotesSection = ({
	relation,
	topicId,
	allowCreate,
	notesList,
}: {
	relation: Relations.Notes;
	topicId: NoteTopicId;
	allowCreate: boolean;
	notesList: React.ReactNode;
}) => {
	const [isEditing, setIsEditing] = useState(false);

	if (!relation) return null;

	return (
		<div className="flex flex-col min-h-fit gap-[16px]">
			<div className="flex flex-row justify-between items-center w-full px-6">
				<p className="text-gray-400 font-[425] text-[12px]">Notes</p>
				{allowCreate && (
					<div
						className="text-[12px] flex items-center justify-center cursor-pointer text-gray-400 hover:text-gray-800"
						onClick={() => setIsEditing(true)}
					>
						<HiPlus />
					</div>
				)}
			</div>
			{isEditing && (
				<AdminNoteEditor
					relation={relation}
					topicId={topicId}
					content=""
					closeEditor={() => setIsEditing(false)}
				/>
			)}
			{notesList}
		</div>
	);
};
