import { actionItemStatus } from "@inrev/common";
import { DateTime } from "luxon";
import { HiCheckCircle } from "react-icons/hi2";
import { ActionItem } from "../../../../../../domain/admin/action-item/type";
import { AdminAssigneeControls } from "../../../AdminAssigneeControls";
import { AdminActionItemLayout } from "../layouts/AdminActionItemLayout";
import { ActionItemContentDisplay } from "./ActionItemContentDisplay";
import { ActionItemDateDisplay } from "./ActionItemDateDisplay";

export const AdminActionItem = ({
	actionItem,
	setCurrentActionItem,
	containerClassName,
}: {
	actionItem: ActionItem;
	setCurrentActionItem: (actionItem: ActionItem) => void;
	containerClassName?: string;
}) => {
	const statusIcon =
		actionItem.status === actionItemStatus.complete ? (
			<HiCheckCircle
				className="align-top fill-[#34B668] w-[19.7px] h-[19.7px] mx-[-2px] mt-[3.5px]"
				onClick={() => {
					console.log("this is the update function", {
						...actionItem,
						completedAt: undefined,
						completedByUserId: undefined,
						status: actionItemStatus.open,
					});
				}}
			/>
		) : (
			<div
				className="w-[16px] h-[16px] rounded-full border-gray-500 border hover:bg-gray-300 cursor-pointer mt-[4.5px] box-border"
				onClick={() => {
					console.log("this is the update function", {
						...actionItem,
						completedAt: DateTime.now(),
						status: actionItemStatus.complete,
					});
				}}
			/>
		);

	const content = (
		<>
			<div
				className="flex flex-col justify-start gap-2 flex-grow"
				onDoubleClick={() => setCurrentActionItem(actionItem)}
			>
				<ActionItemContentDisplay title={actionItem.title} completed={!!actionItem.completedAt} />
				<ActionItemDateDisplay dueAt={actionItem.dueAt} completedAt={actionItem.completedAt} />
			</div>
			<div className="flex items-center justify-center self-start min-w-fit">
				<AdminAssigneeControls
					assignedEntity={{ entityType: "actionItem", entityId: actionItem.id }}
					currentAssignee={actionItem.assigneeId}
				/>
			</div>
		</>
	);

	return (
		<AdminActionItemLayout
			statusIcon={statusIcon}
			content={content}
			containerClassName={containerClassName}
		/>
	);
};
