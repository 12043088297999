import { ErrorKey } from "@inrev/common";
import * as Sentry from "@sentry/react";
import { ReactNode, createContext, useEffect, useState } from "react";
import { HiOutlineExclamationCircle } from "react-icons/hi2";
import { Modal } from "../components/layout/Modal";
import { cn } from "../components/lib/utils";
import { Button } from "../components/ui/Button";
import { Card } from "../components/ui/Card";

export const GlobalErrorMessageModalContext = createContext<{
	triggerErrorModal: (err: Error, params?: Partial<ErrorMessage>) => void;
}>(undefined!);

type GlobalErrorMessageModalWrapperProps = {
	children: ReactNode;
};

type Severity = "error" | "warn" | "info";

type ErrorMessage = {
	header: string;
	description: string;
	severity?: Severity;
};

const messageMap: Record<ErrorKey, ErrorMessage> = {
	max_file_size: {
		header: "File size limit exceeded",
		description: "Please upload a file that is less than 30MB.",
	},
	broker_of_record_account: {
		header: "Permissions error",
		description: "You are not the broker of record for this account.",
	},
	broker_of_record_bond: {
		header: "Permissions error",
		description: "You are not the broker of record for this bond.",
	},
	broker_of_record_quote: {
		header: "Permissions error",
		description: "You are not the broker of record for this bond request.",
	},
	indemnity_individual_exists: {
		header: "Invalid operation",
		description:
			"Individual is on a completed indemnity agreement and cannot be removed at this time.",
	},
	indemnity_company_exists: {
		header: "Invalid operation",
		description:
			"Company is on a completed indemnity agreement and cannot be removed at this time.",
	},
	indemnity_exists: {
		header: "The indemnity agreement already exists",
		description:
			"The indemnity agreement was already created by another user. Please refresh your page to view the updated details.",
	},
	default: {
		header: "Something went wrong",
		description: `An inRev engineer has been notified of your issue and is investigating. In some cases, it's useful to refresh the page and try again. \n\n Apologies for the inconvenience.`,
	},
};

const isApiError = (err: unknown): err is ApiError => {
	return err instanceof ApiError;
};

export const GlobalErrorHandlingProvider = ({ children }: GlobalErrorMessageModalWrapperProps) => {
	const [error, setError] = useState<ErrorMessage | undefined>(undefined);
	const triggerErrorModal = (err: Error, params?: Partial<ErrorMessage>) => {
		Sentry.withScope((scope) => {
			if (isApiError(err)) {
				scope.setTransactionName(`Request Error: ${err.statusCode ?? "Unknown"}`);
				scope.setTag("status_code", err.statusCode ?? "Unknown");
				scope.setTag("key", err.key ?? "Unknown");
			}
			scope.setTag("error_name", err.name);
			scope.captureException(err);
		});
		const baseMessage = isApiError(err)
			? messageMap[err.key || ErrorKey.default]
			: messageMap.default;

		setError({
			...baseMessage,
			...params,
		});
	};

	useEffect(() => {
		const handler = (event: ErrorEvent | PromiseRejectionEvent) => {
			event.preventDefault();
			const error = event instanceof ErrorEvent ? event.error : event.reason;
			triggerErrorModal(error);
		};

		// Catch both sync and async errors at window level
		window.addEventListener("error", handler);
		window.addEventListener("unhandledrejection", handler);

		return () => {
			window.removeEventListener("error", handler);
			window.removeEventListener("unhandledrejection", handler);
		};
	}, []);

	const severityStyles: Record<Severity, string> = {
		error: "border-red-500 bg-red-50 text-red-600/90",
		warn: "border-yellow-500 bg-yellow-50 text-yellow-600/90",
		info: "border-blue-500 bg-blue-50 text-blue-600/90",
	};

	return (
		<>
			<GlobalErrorMessageModalContext.Provider value={{ triggerErrorModal }}>
				{children}
			</GlobalErrorMessageModalContext.Provider>
			{error && (
				<Modal onClickOutside={() => setError(undefined)}>
					<Card className="flex flex-col items-center gap-y-[24px] p-[24px] w-[500px] max-w-[500px]">
						<div
							className={cn(
								"flex space-x-[28px] w-full px-[28px] py-[22px] border border-opacity-40 rounded",
								severityStyles[error.severity || "error"],
							)}
						>
							<HiOutlineExclamationCircle className="text-[32px] stroke-[1.75] shrink-0 mt-[3px]" />
							<div className="flex flex-col space-y-[10px]">
								<span className="text-[20px] font-semibold">{error.header}</span>
								<span className="text-[15px] font-medium whitespace-pre-line">
									{<p>{error.description}</p>}
								</span>
							</div>
						</div>
						<Button onClick={() => setError(undefined)} color="gray" filled className="w-1/2">
							OK
						</Button>
					</Card>
				</Modal>
			)}
		</>
	);
};

type ApiErrorResponse = {
	name: string;
	message: string;
	path: string;
	statusCode: string;
	timestamp: string;
	key?: ErrorKey;
};

export class ApiError extends Error {
	path: string;
	statusCode: string;
	timestamp: string;
	key?: ErrorKey;
	requestUrl: string;
	requestMethod: string;
	requestBody?: any;

	constructor(
		apiError: ApiErrorResponse,
		requestContext: {
			url: string;
			method: string;
			body?: any;
		},
	) {
		super(apiError.message);
		this.name = apiError.name;
		this.path = apiError.path;
		this.key = apiError.key;
		this.statusCode = apiError.statusCode;
		this.timestamp = apiError.timestamp;
		this.requestUrl = requestContext.url;
		this.requestMethod = requestContext.method;
		this.requestBody = requestContext.body;

		Object.setPrototypeOf(this, ApiError.prototype);
	}
}
