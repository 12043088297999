import { z } from 'zod';
import { SuretyType, usStateOrTerritorySchema, naicsCodeSchema, contractDamageSchema, contractHazardSchema } from '../../../../../enums.mjs';
import { city } from '../../../../../types/opaque.mjs';
import { safeNumberSchema, currencySchema } from '../../../../../types/utils.mjs';
import '../../../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../../../types/credit-report/company-credit-report.mjs';
import '../../../../../types/credit-report/individual-credit-report.mjs';
import '../../../../../types/payment/payment-template.mjs';
import '../../../../../types/surety/account/surety-account.types.mjs';
import '../../../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../../../types/surety/bond/surety-bond.types.mjs';
import '../../../../../types/surety/financial-statement/surety-financial-statement.mjs';
import { dataSchema } from '../../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../../../../types/user/user.types.mjs';
import '../../../../../types/entityRelation/entityRelation.mjs';
import { Submit as Submit$1 } from '../../surety-quote.dto.mixins.mjs';

var Submit;
(function (Submit) {
    (function (Request) {
        (function (Base) {
            Base.baseSchema = Submit$1.Request.Base.baseSchema(SuretyType.contract).extend({
                draftData: dataSchema,
            });
        })(Request.Base || (Request.Base = {}));
        (function (Bond) {
            let Project;
            (function (Project) {
                Project.baseSchema = z.object({
                    description: z.string().trim(),
                    address: z.object({
                        city: city,
                        state: usStateOrTerritorySchema,
                    }),
                    scopeOfWork: z.array(naicsCodeSchema).min(1, "At least one scope required"),
                    monthsToComplete: safeNumberSchema.min(1, "Must be at least 1 month"),
                    contractWarrantyMonths: safeNumberSchema.nonnegative("Must be non-negative"),
                    contractDamages: z.array(contractDamageSchema),
                    contractHazards: z.array(contractHazardSchema),
                });
            })(Project = Bond.Project || (Bond.Project = {}));
            Bond.baseSchema = z.object({
                bondAmount: currencySchema,
                tListingRequired: z.boolean(),
                project: Project.baseSchema,
            });
        })(Request.Bond || (Request.Bond = {}));
    })(Submit.Request || (Submit.Request = {}));
})(Submit || (Submit = {}));

export { Submit };
