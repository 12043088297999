import { z } from 'zod';
import { indemnityAgreementSignatureMethodSchema, suretyTypeSchema } from '../../../../enums.mjs';
import { companyContactId, email, individualContactId, isoDateTimeSchema } from '../../../../types/opaque.mjs';
import { zswitch } from '../../../../types/utils.mjs';
import '../../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../../types/credit-report/company-credit-report.mjs';
import '../../../../types/credit-report/individual-credit-report.mjs';
import '../../../../types/payment/payment-template.mjs';
import '../../../../types/surety/account/surety-account.types.mjs';
import '../../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../../types/surety/bond/surety-bond.types.mjs';
import '../../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../../../types/user/user.types.mjs';
import '../../../../types/entityRelation/entityRelation.mjs';

var Get;
(function (Get) {
    (function (Response) {
        let Pending;
        (function (Pending) {
            Pending.schema = z.object({
                status: z.literal("pending"),
                signatureMethod: indemnityAgreementSignatureMethodSchema,
                indemnitors: z.object({
                    companies: z.array(z.object({
                        id: companyContactId,
                        name: z.string(),
                        signerEmail: email.optional(),
                        completed: z.boolean().optional(),
                    })),
                    individuals: z
                        .array(z.object({
                        id: individualContactId,
                        name: z.string(),
                        signerEmail: email.optional(),
                        completed: z.boolean().optional(),
                    }))
                        .default([]),
                }),
            });
        })(Pending = Response.Pending || (Response.Pending = {}));
        let Completed;
        (function (Completed) {
            Completed.schema = Pending.schema.extend({
                status: z.literal("complete"),
                completedAt: isoDateTimeSchema,
            });
        })(Completed = Response.Completed || (Response.Completed = {}));
        Response.schema = zswitch((input) => {
            switch (input.status) {
                case "pending":
                    return Pending.schema;
                case "complete":
                    return Completed.schema;
                default:
                    return z.never();
            }
        });
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));
var UploadCompleted;
(function (UploadCompleted) {
    (function (Request) {
        Request.schema = z.object({
            suretyType: suretyTypeSchema,
            file: z.object({
                name: z.string(),
                base64: z.string(),
            }),
        });
    })(UploadCompleted.Request || (UploadCompleted.Request = {}));
})(UploadCompleted || (UploadCompleted = {}));

export { Get, UploadCompleted };
