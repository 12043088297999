import { z } from 'zod';
import '../../../../../types/opaque.mjs';
import { zswitch } from '../../../../../types/utils.mjs';
import '../../../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../../../types/credit-report/company-credit-report.mjs';
import '../../../../../types/credit-report/individual-credit-report.mjs';
import '../../../../../types/payment/payment-template.mjs';
import '../../../../../types/surety/account/surety-account.types.mjs';
import '../../../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../../../types/surety/bond/surety-bond.types.mjs';
import '../../../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../../../../types/user/user.types.mjs';
import '../../../../../types/entityRelation/entityRelation.mjs';
import { Get as Get$1 } from './contract/submitted-contract-surety-quote-preview.dto.mjs';
import * as submittedContractSuretyQuotePreview_dto from './contract/submitted-contract-surety-quote-preview.dto.mjs';
export { submittedContractSuretyQuotePreview_dto as Contract };

var Get;
(function (Get) {
    (function (Response) {
        Response.schema = zswitch((input) => {
            switch (input.suretyType) {
                case "contract":
                    return Get$1.Response.schema;
                default:
                    return z.never();
            }
        });
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));

export { Get };
