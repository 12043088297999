import { z } from 'zod';
import { Get as Get$2 } from '../../../../surety/bond/contract/contract-surety-bond-mixins.dto.mjs';
import { contractSuretyTypeSchema, suretyBondStatusSchema, suretyBondRenewalMethodSchema, dateTimeSchema, suretyBondIssuanceMethodSchema, suretyBondFormTypeSchema } from '../../../../../enums.mjs';
import { userId, percentSchema, carrierName, suretyAccountId, firstName, lastName, email, phoneNumber, companyContactId } from '../../../../../types/opaque.mjs';
import { currencySchema } from '../../../../../types/utils.mjs';
import '../../../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../../../types/credit-report/company-credit-report.mjs';
import '../../../../../types/credit-report/individual-credit-report.mjs';
import '../../../../../types/payment/payment-template.mjs';
import '../../../../../types/surety/account/surety-account.types.mjs';
import '../../../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import { Data as Data$1, Obligee } from '../../../../../types/surety/bond/surety-bond.types.mjs';
import '../../../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../../../../types/user/user.types.mjs';
import '../../../../../types/entityRelation/entityRelation.mjs';
import { Data } from '../../../../../types/surety/bond/contract/contract-surety-bond.types.mixins.mjs';
import { Get as Get$1 } from '../admin-surety-bond-mixins.dto.mjs';

var Get;
(function (Get) {
    (function (Response) {
        Response.baseSchema = Get$1.Response.baseSchema
            .extend(Get$2.Response.rawSchema.shape)
            .extend({
            project: Data.projectSchema,
        });
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));
var Import;
(function (Import) {
    (function (Request) {
        Request.baseSchema = z.object({
            suretyType: z.literal("contract"),
            contractSuretyType: contractSuretyTypeSchema,
            agentId: userId,
            agencyCommissionPercentage: percentSchema.nullish(),
            bond: z.object({
                status: suretyBondStatusSchema,
                number: z.string(),
                bondAmount: currencySchema,
                carrierName: carrierName,
                suretyAccountId: suretyAccountId,
                renewalMethod: suretyBondRenewalMethodSchema.nullish(),
                closedAt: dateTimeSchema.nullish(),
                continuous: z.boolean(),
                cancellable: z.boolean(),
                pointOfContact: z
                    .object({
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    phone: phoneNumber.nullish(),
                })
                    .nullish(),
                notes: z.string().array(),
                sfaaCode: z
                    .string()
                    .length(3)
                    .regex(/^[6-8][0-9]{2}/, "Invalid"),
                companyContactId: companyContactId,
                data: Data$1.schema,
                obligees: Obligee.schema.array(),
            }),
            bondForm: z.object({
                issuanceMethod: suretyBondIssuanceMethodSchema,
                effectiveDate: dateTimeSchema,
                data: z.object({
                    bondFormType: suretyBondFormTypeSchema,
                }),
            }),
            issuedBondFormFile: z.object({
                name: z.string(),
                base64: z.string(),
            }),
        });
    })(Import.Request || (Import.Request = {}));
})(Import || (Import = {}));

export { Get, Import };
