import { z } from 'zod';
import { suretyAccountUnderwritingStatusSchema, contractSuretyAccountUnderwritingModelSizeSchema, indemnityAgreementSignatureMethodSchema, naicsCodeSchema } from '../../../../../enums.mjs';
import { rateSchema, companyContactId, email, individualContactId, isoDateTimeSchema } from '../../../../../types/opaque.mjs';
import { currencySchema, zswitch, exposureSchema } from '../../../../../types/utils.mjs';
import '../../../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../../../types/credit-report/company-credit-report.mjs';
import '../../../../../types/credit-report/individual-credit-report.mjs';
import '../../../../../types/payment/payment-template.mjs';
import '../../../../../types/surety/account/surety-account.types.mjs';
import '../../../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../../../types/surety/bond/surety-bond.types.mjs';
import '../../../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../../../../types/user/user.types.mjs';
import '../../../../../types/entityRelation/entityRelation.mjs';

var Get;
(function (Get) {
    (function (Response) {
        let Underwriting;
        (function (Underwriting) {
            Underwriting.schema = z.object({
                status: suretyAccountUnderwritingStatusSchema,
                reviewReasons: z.array(z.object({ description: z.string() })),
                supplementalRequirements: z.array(z.string()).default([]),
                availableModelSizes: z.array(contractSuretyAccountUnderwritingModelSizeSchema),
            });
        })(Underwriting = Response.Underwriting || (Response.Underwriting = {}));
        let BondingLine;
        (function (BondingLine) {
            BondingLine.schema = z.object({
                rate: rateSchema.optional(),
                singleLimit: currencySchema.optional(),
                aggregateLimit: currencySchema.optional(),
            });
        })(BondingLine = Response.BondingLine || (Response.BondingLine = {}));
        let IndemnityAgreement;
        (function (IndemnityAgreement) {
            let Incomplete;
            (function (Incomplete) {
                Incomplete.schema = z.object({
                    status: z.literal("incomplete"),
                    allowedSignatureMethods: z.array(indemnityAgreementSignatureMethodSchema).min(1),
                    indemnitors: z.object({
                        companies: z.array(z.object({
                            id: companyContactId,
                            name: z.string(),
                            signerEmail: email.optional(),
                            completed: z.boolean().optional(),
                        })),
                        individuals: z
                            .array(z.object({
                            id: individualContactId,
                            name: z.string(),
                            signerEmail: email.optional(),
                            completed: z.boolean().optional(),
                        }))
                            .default([]),
                    }),
                });
            })(Incomplete = IndemnityAgreement.Incomplete || (IndemnityAgreement.Incomplete = {}));
            let Pending;
            (function (Pending) {
                Pending.schema = Incomplete.schema.extend({
                    status: z.literal("pending"),
                    signatureMethod: indemnityAgreementSignatureMethodSchema,
                });
            })(Pending = IndemnityAgreement.Pending || (IndemnityAgreement.Pending = {}));
            let Completed;
            (function (Completed) {
                Completed.schema = Pending.schema.extend({
                    status: z.literal("complete"),
                    completedAt: isoDateTimeSchema,
                });
            })(Completed = IndemnityAgreement.Completed || (IndemnityAgreement.Completed = {}));
            IndemnityAgreement.schema = zswitch((input) => {
                switch (input.status) {
                    case "incomplete":
                        return Incomplete.schema;
                    case "pending":
                        return Pending.schema;
                    case "complete":
                        return Completed.schema;
                    default:
                        return z.never();
                }
            });
        })(IndemnityAgreement = Response.IndemnityAgreement || (Response.IndemnityAgreement = {}));
        Response.schema = z.object({
            underwriting: Underwriting.schema,
            bondingLine: BondingLine.schema.optional(),
            naicsCodes: naicsCodeSchema.array(),
            exposure: exposureSchema,
            indemnityAgreement: IndemnityAgreement.schema,
        });
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));

export { Get };
