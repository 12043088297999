import { z } from 'zod';
import { individualCreditReportFailureReasonSchema } from '../../enums.mjs';
import { individualCreditReportId, fico, individualContactId, isoDateTimeSchema, individualOFACCheckId } from '../../types/opaque.mjs';
import { buildCreditReportVendorResponseDtoSchema } from '../../types/utils.mjs';
import '../../types/agency/carrier-agency-relationship.types.mjs';
import '../../types/credit-report/company-credit-report.mjs';
import { Vendor as Vendor$1, OFAC as OFAC$1 } from '../../types/credit-report/individual-credit-report.mjs';
import '../../types/payment/payment-template.mjs';
import '../../types/surety/account/surety-account.types.mjs';
import '../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../types/surety/bond/surety-bond.types.mjs';
import '../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../types/user/user.types.mjs';
import '../../types/entityRelation/entityRelation.mjs';

var Vendor;
(function (Vendor) {
    Vendor.responseSchema = buildCreditReportVendorResponseDtoSchema(Vendor$1.reportSchema);
})(Vendor || (Vendor = {}));
var Admin;
(function (Admin) {
    (function (Response) {
        Response.schema = z.object({
            id: individualCreditReportId,
            success: z.boolean(),
            failureReason: individualCreditReportFailureReasonSchema.optional(),
            bankruptcy: z.boolean().optional(),
            score: fico.optional(),
            individualContactId: individualContactId,
            createdAt: isoDateTimeSchema,
            updatedAt: isoDateTimeSchema,
        });
    })(Admin.Response || (Admin.Response = {}));
})(Admin || (Admin = {}));
var OFAC;
(function (OFAC) {
    (function (Vendor) {
        Vendor.responseSchema = buildCreditReportVendorResponseDtoSchema(OFAC$1.Vendor.reportSchema);
    })(OFAC.Vendor || (OFAC.Vendor = {}));
    (function (Admin) {
        (function (Response) {
            Response.schema = z.object({
                id: individualOFACCheckId,
                success: z.boolean(),
                hit: z.boolean(),
                individualContactId: individualContactId,
                createdAt: isoDateTimeSchema,
                updatedAt: isoDateTimeSchema,
            });
        })(Admin.Response || (Admin.Response = {}));
    })(OFAC.Admin || (OFAC.Admin = {}));
})(OFAC || (OFAC = {}));

export { Admin, OFAC, Vendor };
