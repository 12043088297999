import { z } from 'zod';
import '../../../../../types/opaque.mjs';
import { exposureSchema } from '../../../../../types/utils.mjs';
import '../../../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../../../types/credit-report/company-credit-report.mjs';
import '../../../../../types/credit-report/individual-credit-report.mjs';
import '../../../../../types/payment/payment-template.mjs';
import '../../../../../types/surety/account/surety-account.types.mjs';
import '../../../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../../../types/surety/bond/surety-bond.types.mjs';
import '../../../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../../../../types/user/user.types.mjs';
import '../../../../../types/entityRelation/entityRelation.mjs';
import { Get as Get$1 } from '../../submitted/contract/submitted-contract-surety-account.dto.mjs';

var Get;
(function (Get) {
    Get.responseSchema = z.object({
        bondingLine: Get$1.Response.BondingLine.schema.optional(),
        exposure: exposureSchema.pick({ total: true }),
    });
})(Get || (Get = {}));

export { Get };
