import { z } from 'zod';
import { suretyTypeSchema } from '../../../../enums.mjs';
import { carrierName, rateSchema, isoDateTimeSchema } from '../../../../types/opaque.mjs';
import { safeNumberSchema, exposureSchema, suretyAccountBondingLineSchema } from '../../../../types/utils.mjs';
import '../../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../../types/credit-report/company-credit-report.mjs';
import '../../../../types/credit-report/individual-credit-report.mjs';
import '../../../../types/payment/payment-template.mjs';
import '../../../../types/surety/account/surety-account.types.mjs';
import '../../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../../types/surety/bond/surety-bond.types.mjs';
import '../../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../../../types/user/user.types.mjs';
import '../../../../types/entityRelation/entityRelation.mjs';

var Get;
(function (Get) {
    (function (Response) {
        Response.schema = z.object({
            id: z.string().uuid(),
            suretyType: suretyTypeSchema,
            carrierName: carrierName,
            rate: rateSchema,
            rateTemplateVersion: z.string(),
            singleLimit: safeNumberSchema,
            aggregateLimit: safeNumberSchema,
            exposure: exposureSchema,
            autoUpdate: z.boolean(),
            createdAt: isoDateTimeSchema,
        });
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));
var Update;
(function (Update) {
    (function (Request) {
        Request.schema = z.object({
            suretyType: suretyTypeSchema,
            carrierName: carrierName,
            bondingLine: suretyAccountBondingLineSchema.omit({ isPublished: true }),
        });
    })(Update.Request || (Update.Request = {}));
})(Update || (Update = {}));

export { Get, Update };
