import { ActionItemId, Relations, UserId } from "@inrev/common";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { Modal } from "../../../../../components/layout/Modal";
import { Spinner } from "../../../../../components/ui/Spinner";
import { useFetchAdminActionItemsForRelation } from "../../../../../domain/admin/action-item/api";
import { ActionItem } from "../../../../../domain/admin/action-item/type";
import { AdminActionItemView } from "./AdminActionItemView";
import { AdminActionItem } from "./components/AdminActionItem";

export const AdminActionItemTool = ({
	relation,
	defaultUnderwriterId,
}: {
	relation: Relations.ActionItems;
	defaultUnderwriterId: UserId;
}) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { actionItems, actionItemsIsLoading } = useFetchAdminActionItemsForRelation(relation);

	const currentActionItem = useMemo(() => {
		const paramActionItemId = searchParams.get("actionItemId") as ActionItemId;
		if (paramActionItemId === null) {
			return undefined;
		}
		if (actionItems !== undefined) {
			const actionItem = actionItems[paramActionItemId];
			if (actionItem === undefined) {
				setSearchParams((prev) => {
					prev.delete("actionItemId");
					return prev;
				});
				return undefined;
			}
			return actionItem;
		}
	}, [searchParams, actionItems]);

	const setCurrentActionItem = (actionItem?: ActionItem) => {
		if (actionItem === undefined) {
			setSearchParams((prev) => {
				prev.delete("actionItemId");
				return prev;
			});
		} else {
			setSearchParams((prev) => {
				prev.set("actionItemId", actionItem.id);
				return prev;
			});
		}
	};

	if (actionItems === undefined || actionItemsIsLoading) {
		return (
			<div className="flex justify-center items-center">
				<Spinner />
			</div>
		);
	}

	return (
		<>
			<AdminActionItemView
				defaultUnderwriterId={defaultUnderwriterId}
				actionItemList={actionItems}
				relation={relation}
				setCurrentActionItem={setCurrentActionItem}
			/>
			{currentActionItem && (
				<Modal onClickOutside={() => setCurrentActionItem()}>
					<div className="bg-white rounded-lg">
						<AdminActionItem
							actionItem={currentActionItem}
							setCurrentActionItem={setCurrentActionItem}
							containerClassName="border-b-0"
						/>
					</div>
				</Modal>
			)}
		</>
	);
};
