// This icon needs to dynamically change based on the number of uncompleted action items currently assigned to you

import { Relations } from "@inrev/common";
import { MdChecklist } from "react-icons/md";
import { ToolIcon } from "../../../../../../components/ui/ToolIcon";
import { useFetchAdminActionItemsForRelation } from "../../../../../../domain/admin/action-item/api";
import { useAuthenticatedUser } from "../../../../../../providers/AuthenticatedUserProvider";

export const AdminActionItemIcon = ({ relation, ...rest }: { relation: Relations.ActionItems }) => {
	const { user } = useAuthenticatedUser();
	const { actionItems } = useFetchAdminActionItemsForRelation(relation);

	const uncompletedActionItems = Object.values(actionItems).filter(
		(actionItem) => actionItem.assigneeId === user.id && !actionItem.completedAt,
	).length;

	return (
		<>
			{uncompletedActionItems > 0 ? (
				<div className="relative">
					<ToolIcon Icon={MdChecklist} {...rest} />
					<div className="absolute w-[18px] -top-[3px] -right-[12px] text-[10px] font-bold text-white bg-red-500 rounded-full h-[13px] flex justify-center items-center">
						{uncompletedActionItems}
					</div>
				</div>
			) : (
				<ToolIcon Icon={MdChecklist} {...rest} />
			)}
		</>
	);
};
