import { UserId } from "@inrev/common";
import { useMemo, useState } from "react";
import { AssigneeUser, ManageAssigneeModal } from "../../../AdminAssigneeControls";

export const ActionItemSelectAssignee = ({
	userList,
	defaultUnderwriterId,
	selectedUserId,
	setUserId,
}: {
	userList: AssigneeUser[];
	defaultUnderwriterId: UserId;
	selectedUserId: UserId | undefined;
	setUserId: (userId: UserId) => void;
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const currentAssignee = useMemo(() => {
		return (
			userList.find((user) => user.id === selectedUserId) ||
			userList.find((user) => user.id === defaultUnderwriterId)
		);
	}, [userList, selectedUserId]);

	const onSubmit = (userId: UserId) => {
		setUserId(userId);
		setIsOpen(false);
	};

	return (
		<div>
			<div
				className="flex items-center justify-center w-[28px] h-[28px] text-[12px] font-medium text-gray-700 border border-gray-400 bg-white hover:text-gray-800 hover:border-gray-600 rounded-full cursor-pointer"
				onClick={() => setIsOpen(true)}
			>
				{currentAssignee
					? `${currentAssignee.firstName[0]}${currentAssignee.lastName[0]}`.toUpperCase()
					: "??"}
			</div>
			{isOpen && (
				<ManageAssigneeModal
					userList={userList}
					header={
						<div className="flex flex-col">
							<span className="text-[15px] font-[550]">Assign Underwriter</span>
						</div>
					}
					selectedUserId={defaultUnderwriterId}
					onClose={() => setIsOpen(false)}
					onSubmit={onSubmit}
					confirmText="Select"
				/>
			)}
		</div>
	);
};
