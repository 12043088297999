import { PopoverPanel } from "@headlessui/react";
import { Relations, UserId, actionItemStatus } from "@inrev/common";
import { DateTime } from "luxon";
import { useState } from "react";
import { HiCheckCircle } from "react-icons/hi2";
import { DatePicker } from "../../../../../../components/ui/DatePicker";
import { Menu } from "../../../../../../components/ui/Menu";
import { useCreateActionItem } from "../../../../../../domain/admin/action-item/api";
import { useFetchAdminUsers } from "../../../../../../domain/admin/admin-user/api";
import { useAuthenticatedUser } from "../../../../../../providers/AuthenticatedUserProvider";
import { assigneeUserFromQuery } from "../../../AdminAssigneeControls";
import { ActionItemSelectAssignee } from "../components/ActionItemSelectAssignee";
import { ActionItemTitleInput } from "../components/ActionItemTitleInput";

export const AdminActionItemCreateSection = ({
	isOpen,
	relation,
	defaultUnderwriterId,
	close,
}: {
	isOpen: boolean;
	relation: Relations.ActionItems;
	defaultUnderwriterId: UserId;
	close: () => void;
}) => {
	if (!isOpen) return null;

	return (
		<CreateActionItem
			relation={relation}
			defaultUnderwriterId={defaultUnderwriterId}
			close={close}
		/>
	);
};

const CreateActionItem = ({
	relation,
	defaultUnderwriterId,
	close,
}: {
	relation: Relations.ActionItems;
	defaultUnderwriterId: UserId;
	close: () => void;
}) => {
	const { user } = useAuthenticatedUser();
	const { createActionItem } = useCreateActionItem();
	const { adminUsers = [] } = useFetchAdminUsers();
	const [isComplete, setIsComplete] = useState(false);
	const [title, setTitle] = useState("");
	const [dueAt, setDueAt] = useState<DateTime>(DateTime.utc());
	const [assigneeId, setAssigneeId] = useState<UserId>(defaultUnderwriterId);
	const [errors, setErrors] = useState<{ title?: string }>({});

	const createFn = () => {
		validateInput();
		if (Object.keys(errors).length > 0) return;
		const actionItem = {
			title,
			content: "", // should content be available on creation?
			status: isComplete ? actionItemStatus.complete : actionItemStatus.open,
			ownerId: user.id,
			assigneeId: assigneeId,
			dueAt: dueAt,
			completedByUserId: isComplete ? assigneeId : undefined,
			completedAt: isComplete ? DateTime.now() : undefined,
			...relation,
		};

		createActionItem(actionItem);
		close();
	};

	const validateInput = () => {
		const errors: { title?: string; dueAt?: string } = {};
		if (!title || title.length === 0) errors.title = "Title is required";
		setErrors(errors);
	};

	return (
		<div>
			<div className="p-[24px] flex flex-col justify-between items-center border-b border-gray-100">
				<div className="flex gap-5 items-center justify-start w-full">
					<div className="w-[18px] self-start">
						{isComplete ? (
							<HiCheckCircle
								className="align-top fill-[#34B668] w-[19.7px] h-[19.7px] mx-[-2px] mt-[3.5px]"
								onClick={() => setIsComplete(!isComplete)}
							/>
						) : (
							<div
								className="w-[16px] h-[16px] rounded-full border-gray-500 border hover:bg-gray-300 cursor-pointer mt-[4.5px] box-border"
								onClick={() => setIsComplete(!isComplete)}
							/>
						)}
					</div>
					<div className="flex flex-col justify-start gap-2 flex-grow">
						<ActionItemTitleInput updateFn={setTitle} title={title} error={errors.title} />

						<div className="flex items-center gap-2">
							<div className="text-[12px] text-gray-500 pt-[2px]">Due: </div>
							<Menu
								button={
									<div className="text-gray-500 text-[12px] font-[450] underline cursor-pointer hover:text-gray-800">
										{dueAt.toFormat("LLL dd, yyyy")}
									</div>
								}
								anchor="top"
							>
								<PopoverPanel>
									{({ close }) => (
										<DatePicker
											selectedValue={dueAt.toJSDate()}
											defaultMonth={DateTime.now()}
											onChange={(value: string | "") => {
												if (!value) return;
												setDueAt(DateTime.fromISO(value));
											}}
											setOpen={() => close()}
										/>
									)}
								</PopoverPanel>
							</Menu>
						</div>
					</div>
					<div className="flex items-center justify-center self-start min-w-fit">
						<ActionItemSelectAssignee
							defaultUnderwriterId={defaultUnderwriterId}
							userList={adminUsers.map(assigneeUserFromQuery)}
							selectedUserId={assigneeId}
							setUserId={setAssigneeId}
						/>
					</div>
				</div>
				<div className="flex justify-center gap-4 w-full mt-5">
					<button
						className="bg-blue-500 text-white px-4 py-[2px] rounded-lg hover:bg-blue-600"
						onClick={createFn}
					>
						Create
					</button>
					<button
						className="bg-gray-500 text-white px-4 py-[2px] rounded-lg hover:bg-gray-600"
						onClick={close}
					>
						Cancel
					</button>
				</div>
			</div>
		</div>
	);
};
