import { z } from 'zod';
import { suretyBondStatusSchema, suretyBondFormTypeSchema } from '../../../enums.mjs';
import { suretyBondPeriodId, rateSchema, percentSchema, isoDateSchema, suretyQuoteId, suretyBondId, carrierName, companyContactId, individualContactId, suretyAccountId, agencyId, suretyBondFormId, timePeriodSchema, userId } from '../../../types/opaque.mjs';
import { currencySchema, attachmentSchema } from '../../../types/utils.mjs';
import '../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../types/credit-report/company-credit-report.mjs';
import '../../../types/credit-report/individual-credit-report.mjs';
import '../../../types/payment/payment-template.mjs';
import '../../../types/surety/account/surety-account.types.mjs';
import '../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import { Obligee } from '../../../types/surety/bond/surety-bond.types.mjs';
import '../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../../types/user/user.types.mjs';
import '../../../types/entityRelation/entityRelation.mjs';
import * as suretyBondPreview_dto from './preview/surety-bond-preview.dto.mjs';
export { suretyBondPreview_dto as Preview };

var Get;
(function (Get) {
    (function (Response) {
        let BondPeriod;
        (function (BondPeriod) {
            BondPeriod.schema = z.object({
                id: suretyBondPeriodId,
                amount: currencySchema,
                rate: rateSchema,
                premium: currencySchema,
                agencyCommission: currencySchema,
                agencyCommissionPercentage: percentSchema,
                effectiveDate: isoDateSchema,
                expirationDate: isoDateSchema.optional(),
                quoteId: suretyQuoteId.nullish(),
            });
        })(BondPeriod = Response.BondPeriod || (Response.BondPeriod = {}));
        Response.baseSchema = z.object({
            id: suretyBondId,
            displayTitle: z.string(),
            status: suretyBondStatusSchema,
            closedAt: isoDateSchema.optional(),
            number: z.string(),
            amount: currencySchema,
            rate: rateSchema,
            premium: currencySchema,
            agencyCommission: currencySchema,
            agencyCommissionPercentage: percentSchema,
            effectiveDate: isoDateSchema,
            expirationDate: isoDateSchema.optional(),
            quoteId: suretyQuoteId.nullish(),
            carrierName: carrierName,
            carrierDisplayName: z.string(),
            principalId: z.union([companyContactId, individualContactId]),
            principalName: z.string(),
            accountId: suretyAccountId,
            accountName: z.string(),
            agencyId: agencyId,
            bondForm: z.object({
                id: suretyBondFormId,
                type: suretyBondFormTypeSchema,
                url: z.string(),
            }),
            bondPeriodLength: timePeriodSchema.optional(),
            bondPeriods: z.array(BondPeriod.schema),
            currentBondPeriodId: suretyBondPeriodId,
            description: z.string(),
            attachments: z.array(attachmentSchema()),
            underwriterUserId: userId,
            obligees: z.array(Obligee.schema),
        });
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));

export { Get };
