export const ActionItemContentDisplay = ({
	title,
	completed,
}: {
	title: string;
	completed: boolean;
}) => {
	return (
		<div className="text-[13.5px]">
			{completed ? (
				<div className="text-slate-500 line-through">{title}</div>
			) : (
				<div className="text-gray-800">{title}</div>
			)}
		</div>
	);
};
