import { z } from 'zod';
import { percentSchema } from '../../../../types/opaque.mjs';
import '../../../../types/utils.mjs';
import '../../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../../types/credit-report/company-credit-report.mjs';
import '../../../../types/credit-report/individual-credit-report.mjs';
import { paymentTemplateSchema } from '../../../../types/payment/payment-template.mjs';
import '../../../../types/surety/account/surety-account.types.mjs';
import '../../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../../types/surety/bond/surety-bond.types.mjs';
import '../../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../../../types/user/user.types.mjs';
import '../../../../types/entityRelation/entityRelation.mjs';

var Create;
(function (Create) {
    (function (Request) {
        Request.schema = z.object({
            powersAndSeals: z.boolean(),
            contract: z
                .object({
                agencyCommissionPercentage: percentSchema,
                netCommissionPercentage: percentSchema,
                paymentChainTemplate: paymentTemplateSchema.array(),
            })
                .nullish(),
        });
    })(Create.Request || (Create.Request = {}));
})(Create || (Create = {}));
var Get;
(function (Get) {
    (function (Response) {
        Response.schema = z.object({
            powersAndSeals: z.boolean(),
            contract: z
                .object({
                agencyCommissionPercentage: percentSchema,
                netCommissionPercentage: percentSchema,
                paymentChainTemplate: paymentTemplateSchema.array(),
            })
                .nullish(),
        });
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));

export { Create, Get };
