import { ActionItemId, Dtos, Relations } from "@inrev/common";
import { DateTime } from "luxon";
import { useContext, useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { GlobalErrorMessageModalContext } from "../../../providers/GlobalErrorHandlingProvider";
import { useRequest } from "../../../utils/request";
import { ActionItem } from "./type";

export const adminActionItemQueryKeys = {
	all: () => ["admin", "actionItems"], // Used for the dashboard
	actionItemsByRelation: (relation: Relations.ActionItems) => [
		"admin",
		"actionItems",
		relation.relationType,
		relation.relationId,
	], // Used for individual page views
};

export type ActionItemList = Record<ActionItemId, ActionItem>;

export const useFetchAllAdminActionItems = () => {
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);
	const queryClient = useQueryClient();
	const { get } = useRequest();

	const actionItems = useQuery({
		queryKey: adminActionItemQueryKeys.all(),
		queryFn: async () => await get<Dtos.ActionItem.Get.Response[]>("/v2/action-items"),
		onSuccess(data) {
			data?.forEach((actionItem) => {
				console.log(actionItem);
				const relation = {
					relationType: actionItem.relationType,
					relationId: actionItem.relationId,
				} as Relations.ActionItems;
				queryClient.setQueryData(adminActionItemQueryKeys.actionItemsByRelation(relation), {
					[actionItem.id]: transformActionItem(actionItem),
				});
			});
		},
		onError: (error: Error) => {
			console.error(error);
			triggerErrorModal(error);
		},
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		staleTime: 1000 * 60 * 5,
	});

	return actionItems;
};

// the idea is to use the id as the key for the object so we don't need to search through the array
const transformActionItems = (actionItems: Dtos.ActionItem.Get.Response[]): ActionItemList =>
	Object.fromEntries(
		actionItems.map((actionItem) => {
			return [actionItem.id, transformActionItem(actionItem)];
		}),
	);

// used mainly to transform incoming dates to DateTime instead of doing it in the component
const transformActionItem = (actionItem: Dtos.ActionItem.Get.Response): ActionItem => {
	return Dtos.ActionItem.Get.Response.schema.parse(actionItem);
};

export const useFetchAdminActionItemsForRelation = (relation: Relations.ActionItems) => {
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);
	const { get } = useRequest();

	const actionItemsRequest = useQuery({
		queryKey: adminActionItemQueryKeys.actionItemsByRelation(relation),
		queryFn: async () => {
			const data = await get<Dtos.ActionItem.Get.Response[]>(
				`/v2/action-items?relationType=${relation.relationType}&relationId=${relation.relationId}`,
			);
			return transformActionItems(data);
		},
		onError: (error: Error) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return useMemo(() => {
		const kvActionItems = (() => {
			const actionItems = actionItemsRequest.data;
			if (actionItems !== undefined && actionItems !== null) {
				return actionItems;
			}
			return {};
		})();
		return {
			actionItems: kvActionItems,
			actionItemsIsLoading: actionItemsRequest.isLoading,
		};
	}, [actionItemsRequest, actionItemsRequest.data]);
};

export const useCreateActionItem = () => {
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);
	const queryClient = useQueryClient();
	const { post } = useRequest();

	const mutation = useMutation({
		mutationFn: async (actionItem: Dtos.ActionItem.Create.Request) =>
			await post<Dtos.ActionItem.Create.Response>("/v2/action-items", actionItem),
		onMutate: async (actionItem) => {
			const relation = {
				relationType: actionItem.relationType,
				relationId: actionItem.relationId,
			} as Relations.ActionItems;

			await queryClient.cancelQueries(adminActionItemQueryKeys.actionItemsByRelation(relation));
			const previousActionItems = queryClient.getQueryData<ActionItemList>(
				adminActionItemQueryKeys.actionItemsByRelation(relation),
			);
			const tempId = crypto.randomUUID() as ActionItemId;
			queryClient.setQueryData<ActionItemList>(
				adminActionItemQueryKeys.actionItemsByRelation(relation),
				(old) => {
					if (old === undefined) return {};
					return {
						...old,
						[tempId]: {
							...{
								...actionItem,
								dueAt: actionItem.dueAt,
								completedAt: actionItem.completedAt,
							},
							...relation,
							id: tempId,
							createdAt: DateTime.now(),
							updatedAt: DateTime.now(),
						},
					};
				},
			);
			return { previousActionItems, tempId, relation };
		},
		onSuccess: (newActionItem, inputData, context) => {
			const relation = {
				relationType: inputData.relationType,
				relationId: inputData.relationId,
			} as Relations.ActionItems;
			if (!context) {
				throw new Error("Missing context of previous state");
			}

			queryClient.setQueryData(
				adminActionItemQueryKeys.actionItemsByRelation(relation),
				(old: ActionItemList | undefined) => {
					if (old === undefined) return transformActionItems([newActionItem]);
					delete old[context.tempId];
					return {
						...old,
						[newActionItem.id]: transformActionItem(newActionItem),
					};
				},
			);
		},
		onError: (error: Error) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return {
		createActionItem: mutation.mutate,
		createActionItemLoading: mutation.isLoading,
	};
};
