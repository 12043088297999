export const ActionItemTitleInput = ({
	title,
	error,
	updateFn,
}: {
	title: string;
	error?: string;
	updateFn?: (title: string) => void;
}) => {
	return (
		<div className="relative">
			<input
				value={title}
				className="w-full h-[26px] bg-white border border-gray-300 text-[12px] px-3 py-2 rounded-md focus:outline-none"
				onChange={updateFn ? (e) => updateFn(e.target.value) : undefined}
				placeholder="New Action Item"
			/>
			{error && <div className="absolute bottom-[-20px] text-red-500 text-[12px]">{error}</div>}
		</div>
	);
};
