import { Dtos, SuretyQuoteId } from "@inrev/common";
import { useContext } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { GlobalErrorMessageModalContext } from "../../../providers/GlobalErrorHandlingProvider";
import { useRequest } from "../../../utils/request";
import { AdminBondRequest } from "./types";

export const useFetchAdminBondRequest = (id: string) => {
	const { get } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const { data, error, isLoading } = useQuery({
		queryKey: ["bondRequests", id],
		queryFn: async () => await get<AdminBondRequest>(`/v2/admin/surety/quotes/${id}`),
		retry: false,
		staleTime: 30000,
		onError: (error: Error) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return { bondRequest: data, bondRequestError: error, bondRequestLoading: isLoading };
};

export const useAdminUpdateBondRequestStatus = (id: SuretyQuoteId) => {
	const queryClient = useQueryClient();
	const { patch } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationKey: ["updateBondRequestStatus", id],
		mutationFn: async (data: Dtos.Admin.SuretyQuote.UpdateStatus.Request) => {
			await patch(`/v2/admin/surety/quotes/${id}/status`, data, "none");
		},
		onSuccess: () => {
			queryClient.refetchQueries({ queryKey: ["bondRequests", id] });
			queryClient.refetchQueries({ queryKey: ["bondRequestPreviews"] });
		},
		onError: (error: Error) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return {
		updateBondRequestStatus: mutation.mutate,
		updateBondRequestStatusIsLoading: mutation.isLoading,
	};
};

export const useUploadCompletedBondForm = (id: SuretyQuoteId) => {
	const queryClient = useQueryClient();
	const { post } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationKey: ["uploadCompletedBondForm", id],
		mutationFn: async (data: Dtos.Admin.SuretyBondForm.Upload.PhysicalIssue.Request) => {
			await post(`/v2/admin/surety/quotes/${id}/bond-form/upload`, data);
		},
		onSuccess: () => {
			queryClient.refetchQueries({ queryKey: ["bondRequests", id] });
			queryClient.refetchQueries({ queryKey: ["bondRequestPreviews"] });
		},
		onError: (error: Error) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return {
		uploadCompletedBondForm: mutation.mutate,
		uploadCompletedBondFormIsLoading: mutation.isLoading,
	};
};

export const useApproveBondFormForAgentIssue = (id: SuretyQuoteId) => {
	const queryClient = useQueryClient();
	const { post } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationKey: ["approveBondFormForAgentIssue", id],
		mutationFn: async (data: Dtos.Admin.SuretyBondForm.Approve.Request) => {
			await post(`/v2/admin/surety/quotes/${id}/bond-form/approve`, data);
		},
		onSuccess: () => {
			queryClient.refetchQueries({ queryKey: ["bondRequests", id] });
		},
		onError: (error: Error) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return {
		approveBondFormForAgentIssue: mutation.mutate,
		approveBondFormForAgentIssueIsLoading: mutation.isLoading,
	};
};

export const useCompleteAgentIssuedBondForm = (id: SuretyQuoteId, upload: boolean) => {
	const queryClient = useQueryClient();
	const { post } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationKey: ["uploadAgentIssuedBondForm", id],
		mutationFn: async (
			data:
				| Dtos.Admin.SuretyBondForm.Upload.AgentIssue.Request
				| Dtos.Admin.SuretyBondForm.Approve.AgentIssue.Request,
		) => {
			return await post<AdminBondRequest>(
				`/v2/admin/surety/quotes/${id}/bond-form/${upload ? "upload" : "approve"}/agent-issue`,
				data,
			);
		},
		onSuccess: (data: AdminBondRequest) => {
			queryClient.setQueryData(["bondRequests", id], data);
			queryClient.refetchQueries({ queryKey: ["bondRequestPreviews"] });
		},
		onError: (error: Error) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return {
		completeAgentIssuedBondForm: mutation.mutate,
		completeAgentIssuedBondFormIsLoading: mutation.isLoading,
	};
};
